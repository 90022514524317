
























import { debounce } from 'lodash';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

import UiCheckbox from '../../components/ui/UiCheckbox.vue';
import UiSelect from '../../components/ui/UiSelect.vue';
import UiTextEdit from '../../components/ui/UiTextEdit.vue';

@Component({
  components: {
    UiTextEdit,
    UiSelect,
    UiCheckbox,
  },
})
export default class BlockchainAddressInput extends BaseVue {
  public apiKey = '';
  public apiSecret = '';
  public apiPassphrase = '';
  public exchangeContactId = '';
  public isPrime = true;
  public isValid = false;

  public debouncedValidate = debounce(this.validateForm.bind(this), 1000);

  public async validateForm() {
    this.isValid = !!this.apiKey && !!this.apiSecret && !!this.apiPassphrase && !!this.exchangeContactId;
  }

  public get contacts() {
    const contacts = this.$store.getters['contacts/ENABLED_CONTACTS'];
    const res: any = {};
    contacts.forEach((contact: any) => (res[contact.name] = contact.id));
    return res;
  }

  @Watch('apiKey')
  @Watch('apiSecret')
  @Watch('apiPassphrase')
  @Watch('exchangeContactId')
  @Watch('isPrime')
  public onWalletTypeChanged() {
    this.debouncedValidate();
    this.$emit('data', {
      createCoinbasePro: {
        apiKey: this.apiKey,
        apiSecret: this.apiSecret,
        apiPassphrase: this.apiPassphrase,
        exchangeContactId: this.exchangeContactId,
        isPrime: this.isPrime,
      },
    });
  }

  @Watch('isValid')
  public validChanged() {
    this.$emit('valid', this.isValid);
  }
}
